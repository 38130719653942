<template>
  <div class="catalogues__modal">
    <div class="catalogues__modal__background">
      <div class="catalogues__modal__content" v-if="!isLoading">
        <div class="catalogues__modal__content__data">
            <div class="technical__modal__content__data__question">
                <label 
                    for="technical-code" 
                    class="technical__modal__content__data__question__label"
                >
                Titulo del Catálogo:
            </label>
                <input 
                    class="technical__modal__content__data__question__input"
                    type="text" 
                    name="technical-code" 
                    id="technical-code"
                    placeholder="Titulo del Catálogo"
                    v-model="catalogue.title"
                    >
            </div>
          <div class="catalogues__modal__content__data__question">
            <label
              for="technical-brand"
              class="catalogues__modal__content__data__question__label"
            >
              Marca del Catálogo:
            </label>
            <select
              name="bad_day"
              class="catalogues__modal__content__data__question__opt"
              v-model="catalogue.brand"
            >
              <option
                :value="brand.name"
                v-for="brand in getAllBrands"
                :key="brand.id"
              >
                {{ brand.name }}
              </option>
            </select>
          </div>
          <div class="catalogues__modal__content__data__question">
            <p v-if="fileName">{{fileName}}</p>
            <button
            class="technical__modal__content__thumbnail__button"
            @click="chooseImage"
          >
            Escoger Catálogo
          </button>
          <input
            type="file"
            name="technical-thumb"
            id="technical-thumb"
            v-show="false"
            ref="technicalThumb"
            @change="onFileChange"
          />
          </div>

          <button
            class="catalogues__modal__content__data__create"
            @click="createSheet"
          >
            Añadir Catálogo
          </button>
        </div>
        <p class="catalogues__modal__content__x" @click="closeModal">x</p>
      </div>
      <div class="catalogues__modal__loader" v-else>Creando Catálogo...</div>
    </div>
  </div>
</template>
  
  <script>
import { mapActions, mapGetters } from "vuex";
export default {
  components: {},
  computed: {
    ...mapGetters("webStore", ["getAllBrands"]),
  },
  data() {
    return {
      catalogue: {
        brand: "",
        title: "",
        file: "",
      },
      fileName: "",
      isLoading: false,
    };
  },

  methods: {
    closeModal() {
      this.cleanTechnicalSheet();
      this.$emit("closeModal");
    },

    chooseImage() {
      this.$refs.technicalThumb.click();
    },

    onFileChange(event) {
      const file = event.target.files[0];
      this.fileName = file.name;
      this.catalogue.file = file;
    },

    async createSheet() {
      try {
        if (
          !this.catalogue.title ||
          !this.catalogue.file ||
          !this.catalogue.brand
        )
          return;
        this.isLoading = true;
        await this.createCatalogue(this.catalogue);
        this.$toast.success("Se agregó la Ficha Técnica");
        this.isLoading = false;
        this.closeModal();
      } catch (e) {
        console.log(e)
        this.$toast.error("Ocurrió un error al crear la Ficha Técnica");
      }
    },

    cleanTechnicalSheet() {
      this.catalogue.brand = "";
      this.catalogue.title = "";
      this.catalogue.file = "";
      this.fileName = "";
    },

    ...mapActions("vCard", ["createCatalogue"]),
  },
};
</script>
  
  <style>
</style>
  